<template lang="pug">
v-dialog(v-model="opened", width="500")
  v-flex.vitrify(xs12, v-if="opened" style="min-height: 10vh; background: rgba(255,255,255,0.8)")
    div.expande-horizontal.wrap.pt-0
      v-flex(xs12).pa-3
        h3.fonte Endereço
      v-flex(xs12).pa-12.pt-6
        div.card-method-form.expande-horizontal.wrap.fonte
          v-flex(xs12)
            v-form(ref="payer_form")
              v-flex(xs7)
                v-text-field(
                  dense
                  label="CEP",
                  v-model="get_compra.address.zip_code"
                  outlined,
                  type="number"
                  ref="cepInput"
                  @input="searchCep"
                  :rules="cepRules"
                  v-mask="['########']"
                  placeholder="########"
                ) 
              div.expande-horizontal
                v-flex(xs9).pr-3
                  v-text-field(
                    dense
                    v-model="get_compra.address.street"
                    label="Endereço",
                    outlined
                  ) 
                v-flex(xs3)
                  v-text-field(
                    dense
                    v-model="get_compra.address.number"
                    label="Nº",
                    ref="addressNumber"
                    @keyup.enter.prevent="saveAddress"
                    outlined
                  ) 
              v-flex(xs12)
                div.expande-horizontal
                  v-flex.mr-3(xs4)
                    v-text-field(
                      v-model="get_compra.address.city"
                      dense,
                      label="Cidade",
                      placeholder="ex: Macapá"
                      outlined
                    ) 
                  v-flex.mr-3(xs4)
                    v-text-field(
                      v-model="get_compra.address.state"
                      dense,
                      label="Estado",
                      placeholder="ex: Amapá"
                      outlined
                    ) 
                  v-flex(xs6)
                    v-text-field(
                      v-model="get_compra.address.district"
                      dense,
                      label="Bairro",
                      placeholder="ex: Zerão"
                      outlined
                    ) 
          v-flex(xs12)
            v-btn.fonte(block,dark,@click="saveAddress",:color="$theme.secondary") Concluir        

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      cepRules: [v => !!v || "preencha seu cep"],
      opened: false
    };
  },
  computed: {
    ...mapGetters(["get_compra", "get_clientes"])
  },
  methods: {
    ...mapActions(["get_cep"]),
    async searchCep() {
      if (this.get_compra.address.zip_code) {
        if (this.get_compra.address.zip_code.length === 8) {
          const res = await axios.get(
            `https://viacep.com.br/ws/${this.get_compra.address.zip_code}/json/`
          );
          if (res.status === 200) {
            this.get_compra.address.street = res.data.logradouro;
            this.get_compra.address.district = res.data.bairro;
            this.get_compra.address.city = res.data.localidade;
            this.get_compra.address.state = res.data.uf;
            this.get_compra.address.bairro = res.data.bairro;
            this.get_compra.address.range_numbers = res.data.complemento;
            this.get_compra.address.number = "";
            this.$refs.addressNumber.focus();
            this.$forceUpdate();
          }
        }
      }
    },
    saveAddress() {
      if (this.$refs.payer_form.validate()) {
        const address = this.get_compra.address;
        this.$store.commit("set_compra", {
          ...this.get_compra,
          address
        });
        this.close();
      }
    },
    open() {
      if (!this.get_compra.address) {
        this.get_compra.address = {};
      }
      this.opened = true;
      setTimeout(() => {
        this.$refs.cepInput.focus();
      }, 300);
    },
    close() {
      this.opened = false;
      setTimeout(() => {
        this.$forceUpdate();
      }, 1000);
    }
  },
  created() {}
};
</script>

<style scoped>
.card-method-card {
  max-width: 300px;
}
.card-title {
  color: #363636;
}
.card-container {
  width: 100%;
  display: flex;
  background: #f0f0f0;
  border: 1px solid #0da8f0;
  border-radius: 10px;
}
</style>
