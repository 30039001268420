<template>
  <v-dialog
    width="800"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_fechamento_caixa"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 py-1">
        <v-btn dark @click="fecha_modal_fechamento_caixa" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte green--text">
          Fechamento de Caixa
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_fechamento_caixa">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-3" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Responsável
              </span>
              <v-text-field
                v-if="get_caixa.funcionario"
                v-model="get_caixa.funcionario.nome"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo
                flat
                outlined
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-3" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Fundo de Caixa
              </span>
              <v-text-field
                v-model="get_caixa.fundo"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo
                flat
                outlined
                @keyup.enter.prevent="fechar_caixa"
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="fechar_caixa" tile block color="green">
          <span class="fonte"> Fechar Caixa </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_modal_fechamento_caixa", "get_caixa", "get_caixas"])
  },
  methods: {
    ...mapActions([
      "listarMeuCaixa",
      "fechar_caixa",
      "fecha_modal_fechamento_caixa"
    ])
  }
};
</script>
