<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_abertura_caixa"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="fecha_modal_abertura_caixa" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte green--text">
          Abertura de Caixa
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-0" icon @click="fecha_modal_abertura_caixa">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <h2 class="fonte">Responsável: {{ getLoggedUser.nome }}</h2>
            </v-flex>

            <v-flex class="px-7" xs12>
              <h2 class="fonte" :style="`color: ${$theme.primary}`">
                Data de abertura -
                {{ $moment().format("DD/MM/YYYY [às] HH:mm") }}
              </h2>
            </v-flex>

            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Fundo de Caixa
              </span>
              <v-text-field
                autofocus
                v-model="get_caixa.fundo"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo
                flat
                @keyup.enter.prevent="validateOpen"
                outlined
                prefix="R$"
                v-mask="['#', '##', '###', '####']"
                :color="$theme.primary"
                label="ex: 200"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validateOpen" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_modal_abertura_caixa", "get_caixa", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["criar_caixa", "fecha_modal_abertura_caixa"]),
    validateOpen() {
      if (this.$refs.form.validate()) {
        this.criar_caixa(this.get_caixa);
      }
    }
  }
};
</script>
