<template>
  <v-dialog
    transition="slide-x-transition"
    style="overflow: hidden;"
    width="800"
    height="800"
    v-model="opened"
  >
    <v-card
      :style="`overflow: ${$vuetify.breakpoint.smAndDown ? 'auto' : 'hidden'};`"
      height="600"
      width="800"
    >
      <v-flex xs12>
        <div class="expande-horizontal wrap">
          <!-- <v-flex xs12 md6>
            <div class="expande-horizontal">
              <v-carousel
                cycle
                :height="$vuetify.breakpoint.smAndDown ? '400' : '512'"
                width="100%"
                class="pa-0 ma-0"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item v-for="(slide, i) in product.imagens" :key="i">
                  <img style="height: 100%;" :src="slide" />
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-flex> -->
          <v-flex class="pt-3" xs12>
            <div class="expande-horizontal">
              <v-flex xs4>
                <div
                  class="fonte column expande-horizontal pa-6 pr-0 font-color"
                >
                  <h3 class="font-orange">
                    {{ $helper.formataSaldo(product.seller_price || 0) }}
                  </h3>
                  <span class="grey--text fonte-micro">Preço final</span>
                </div>
              </v-flex>
              <v-flex class="pl-0 pt-2 pr-6" xs8>
                <v-autocomplete
                  v-if="changePlate"
                  ref="plateInput"
                  :items="products.filter(p => p.type === 'Placa')"
                  item-text="nome"
                  return-object
                  placeholder="Escolha o novo tipo aqui"
                  @change="alterProduct($event)"
                  outlined
                  hide-details
                  class="mt-1"
                  append-outer-icon="mdi-swap-horizontal"
                  @click:append-outer="changePlate = false"
                ></v-autocomplete>
                <v-list v-else dark color="transparent">
                  <v-list-item
                    class="pa-0 ma-0 pl-3 fonte item-list"
                    style="background: #010f50"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="fonte-bold font-orange">{{
                        product.nome
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro font-orange">
                        {{
                          $helper.formataSaldo(product.ultimo_preco_de_compra)
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="nowrap">
                        <v-btn
                          @click.prevent="initChangeTable"
                          icon
                          class="mr-4"
                        >
                          <v-icon :color="$theme.primary"
                            >mdi-swap-horizontal</v-icon
                          >
                        </v-btn>
                        <v-btn @click.prevent="close" icon class="mr-4">
                          <v-icon :color="$theme.primary">mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
            <v-flex
              xs12
              class="pl-6 pr-6 pt-3 adicionals fonte"
              style="height: 458px; overflow: auto;"
            >
              <div
                v-if="product.ficha_tecnica ? true : false"
                class="expande-horizontal column"
              >
                <div class="expande-horizontal">
                  <v-flex xs12>
                    <v-simple-table
                      dark
                      style="witdh: 100%; height: 370px; overflow: auto; background: #f0f0f0;"
                      dense
                    >
                      <template v-slot:default>
                        <thead style="background: #EBEBEB; height: 40px;">
                          <tr
                            style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                          >
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Qtd
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Desc.
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Comprimento
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Largura
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Medida
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Preço
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            ></td>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            style="border-top: 1px solid #D2D4CF;"
                            v-for="(item, i) in product.ficha_tecnica"
                          >
                            <tr
                              class="mt-3"
                              @click="openModalAddProduct(item, `${i}`)"
                              style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                              :key="`product-inn-list-${i}`"
                            >
                              <td
                                @click.prevent="abre_modal_view_caixa(item)"
                                style="color: #878686; width: 10px; border-top: 1px dashed #D2D4CF;"
                                class="fonte fonte-bold"
                              >
                                {{ item.quantity }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ item.descricao ? item.descricao : "" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ item.comprimento ? item.comprimento : "" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ item.largura ? item.largura : "" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ verifyUnit(item) }}
                              </td>
                              <td
                                class="fonte green--text fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ $helper.formataSaldo(item.total) }}
                              </td>
                              <td
                                class="fonte green--text fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                <v-btn @click="removeItem(item, i)" icon small>
                                  <v-icon color="red lighten-2">
                                    mdi-delete-circle-outline
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </div>
              </div>
              <v-form ref="formAddItem">
                <div class="expande-horizontal">
                  <v-flex xs2>
                    <v-text-field
                      label="Quantidade"
                      type="number"
                      ref="qtdFicha"
                      min="1"
                      :rules="[v => !!v || 'Preencha este campo']"
                      @keyup.enter.prevent="$refs.typeField.focus()"
                      v-mask="['#', '##', '###', '####']"
                      v-model="quantidade"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="px-3 cl" xs6>
                    <v-autocomplete
                      label="Tipo"
                      ref="typeField"
                      :items="adicionais"
                      item-text="nome"
                      :rules="[v => !!v || 'Preencha este campo']"
                      return-object
                      @change="$refs.compField.focus()"
                      v-model="descricao"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      ref="compField"
                      label="Comprimento"
                      v-mask="['#,###', '##,###']"
                      v-model="comprimento"
                    ></v-text-field>
                  </v-flex>
                  <span class="pa-4 fonte">x</span>
                  <v-flex xs3>
                    <v-text-field
                      label="Largura"
                      v-mask="['#,###', '##,###']"
                      v-model="largura"
                      @keyup.enter="addFichaTecnica"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pt-2" xs1>
                    <v-btn @click="addFichaTecnica" icon>
                      <v-icon :color="$theme.third"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </v-btn>
                  </v-flex>
                </div>
              </v-form>
              <!-- <div class="expande-horizontal">
                <v-flex xs2>
                  <v-text-field
                    label="Quantidade"
                    type="number"
                    min="0"
                    v-mask="['#', '##', '###', '####']"
                    v-model="quantidadeAd"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-3 cl" xs6>
                  <v-autocomplete
                    label="Adicional"
                    :items="adicionais"
                    item-text="nome"
                    return-object
                    v-model="adicional"
                  ></v-autocomplete>
                </v-flex>
                <v-flex class="pt-2" xs3>
                  <v-btn @click="addFichaTecnica('adicional')" icon>
                    <v-icon :color="$theme.third"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                </v-flex>
              </div> -->
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <v-list
                    class="pa-0 ma-0"
                    v-for="(adicional, index) in product.adicionais"
                    :id="`aditional_${index}`"
                    :key="adicional.nome_grupo"
                  >
                    <v-list-item class="pa-0 ma-0 pl-3 fonte item-list">
                      <v-list-item-content>
                        <v-list-item-title class="pb-1 fonte-bold">
                          {{ adicional.nome_grupo }}
                          <v-btn
                            outlined
                            class="ml-3"
                            :color="
                              adicional.obrigatorio ? 'red darken-2' : 'grey'
                            "
                            x-small
                            dark
                            >{{
                              adicional.obrigatorio ? "obrigatório" : "opcional"
                            }}</v-btn
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte-micro">
                          {{ adicional.quantidade_adicionada || 0 }} de
                          {{ adicional.maximo }} itens adicionados
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-for="(item_adicional, i) in adicional.itens">
                      <v-list-item class="fonte" :key="item_adicional.nome">
                        <v-list-item-content>
                          <v-list-item-title class="fonte">
                            {{
                              item_adicional.descricao_personalizada
                                ? item_adicional.descricao_personalizada
                                : item_adicional.nome
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="fonte fonte-bold green--text"
                          >
                            + {{ $helper.formataSaldo(item_adicional.preco) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="expande-horizontal">
                            <v-btn
                              @click.prevent="removeAditional(i, index)"
                              v-if="
                                item_adicional &&
                                  item_adicional.quantidade_adicionada > 1
                              "
                              icon
                              x-small
                            >
                              <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                              @click.prevent="removeAditional(i, index)"
                              v-if="
                                item_adicional &&
                                  item_adicional.quantidade_adicionada === 1
                              "
                              icon
                              x-small
                            >
                              <v-icon>mdi-delete-circle-outline</v-icon>
                            </v-btn>
                            <h5 class="mx-2 fonte fonte-bold">
                              {{ item_adicional.quantidade_adicionada || 0 }}
                            </h5>
                            <v-btn @click="addAditional(i, index)" icon x-small>
                              <v-icon color="green"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
                <!-- <v-flex class="pt-6" xs12>
                  <v-textarea
                    height="100px"
                    @keyup.enter.prevent="addProduct"
                    hint="Pressione enter para adicionar rapidamente"
                    v-model="product.obs"
                    label="Observações"
                  ></v-textarea>
                </v-flex> -->
              </div>
            </v-flex>
            <div
              class="pl-6 expande-horizontal"
              style="border-top: 1px dashed #e5e5e5;"
            >
              <!-- <v-btn
                @click.prevent="removeProductQtd"
                v-if="product.quantity > 1"
                icon
                small
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn> -->
              <v-btn
                @click.prevent="removeProductOfCart(product)"
                v-if="product.quantity === 1"
                icon
                small
              >
                <v-icon>mdi-delete-circle-outline</v-icon>
              </v-btn>
              <!-- <h3 class="mx-2 fonte fonte-bold">
                {{ product.quantity }}
              </h3>
              <v-btn @click="addProductQtd" icon small>
                <v-icon color="green">mdi-plus-circle-outline</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                tile
                @click.prevent="addProduct"
                color="#47cf72"
                :disabled="product.ficha_tecnica.length === 0 ? true : false"
                class="fonte fonte-bold"
                dark
              >
                Adicionar {{ $helper.formataSaldo(product.seller_price || 0) }}
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    addProductToCart: {
      type: Function,
      required: true
    },
    removeProductOfCart: {
      type: Function,
      required: true
    },
    products: {
      type: Array,
      default: [],
      required: true
    },
    taxFinishing: {
      type: Number,
      default: 30,
      required: true
    },
  },
  data() {
    return {
      changePlate: false,
      opened: false,
      product: {
        quantity: 1,
        imagens: [],
        ficha_tecnica: []
      },
      adicional: "",
      quantidade: "",
      quantidadeAd: "",
      comprimento: "",
      largura: "",
      descricao: "",
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4"
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"]
    };
  },
  methods: {
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    },
    removeItem(item, i) {
      this.product.ficha_tecnica = this.product.ficha_tecnica.filter(
        (it, index) => index !== i
      );
      this.processFinalPrice();
    },
    addFichaTecnica() {
      if (this.$refs.formAddItem.validate()) {
        const type = this.descricao.type;
        if (type && type === "Acabamento") {
          const quantidade = parseInt(this.quantidade);
          const lucro_percentual = parseFloat(this.taxFinishing);
          const preco = parseFloat(
            this.descricao.ultimo_preco_de_compra.replace(",", ".")
          );
          const calculo = preco;
          const taxa = (calculo / 100) * lucro_percentual;
          const total = parseFloat(quantidade * (taxa + calculo)).toFixed(2);
          this.product.ficha_tecnica.push({
            comprimento: 0,
            largura: 0,
            preco,
            descricao: this.descricao.nome,
            calculo,
            taxa,
            type: type,
            total,
            quantity: quantidade
          });
          this.$refs.qtdFicha.focus();
        } else if (
          type &&
          (type === "Peça Principal" || type === "Peça Secundária")
        ) {
          const comprimento = parseFloat(
            this.comprimento.replace(",", ".") || 0
          );
          const quantidade = parseInt(this.quantidade);
          const largura = parseFloat(this.largura.replace(",", ".") || 0);
          const lucro_percentual = parseFloat(this.taxFinishing);
          const preco = parseFloat(
            this.product.ultimo_preco_de_compra.replace(",", ".")
          );
          let calculo, taxa;
          if (largura <= 0.1) {
            let dezporcento = (preco / 100) * 10;
            calculo = quantidade * comprimento * dezporcento;
            taxa = (calculo / 100) * lucro_percentual;
          } else {
            calculo = quantidade * comprimento * largura * preco;
            taxa = (calculo / 100) * lucro_percentual;
          }
          this.product.ficha_tecnica.push({
            comprimento: comprimento,
            largura: largura,
            preco,
            descricao: this.descricao.nome,
            calculo,
            type: type,
            taxa,
            total: parseFloat(taxa + calculo).toFixed(2),
            quantity: quantidade
          });
          this.$refs.qtdFicha.focus();
          this.quantidade = 1;
          this.comprimento = "";
          this.largura = "";
          this.descricao = "";
        }
        this.processFinalPrice();
      }
    },
    ...mapActions(["createGlobalMessage"]),
    open(product) {
      this.opened = true;
      this.product = {
        ...product,
        ficha_tecnica: product.ficha_tecnica ? product.ficha_tecnica : [],
        quantity: product.quantity ? product.quantity : 1,
        seller_price: product.quantity
          ? product.seller_price
          : product.preco_com_markup
      };
      setTimeout(() => {
        this.$refs.qtdFicha.focus();
      }, 300);
    },
    alterProduct(p) {
      let ficha_tecnica = [];
      this.product.ficha_tecnica.map(item => {
        const quantidade = parseInt(item.quantity);
        const lucro_percentual = parseFloat(this.taxFinishing);
        const preco = parseFloat(p.ultimo_preco_de_compra.replace(",", "."));

        if (item.type === "Acabamento") {
          const calculo = parseFloat(item.preco);
          const taxa = (calculo / 100) * lucro_percentual;
          item.total = parseFloat(quantidade * (taxa + calculo)).toFixed(2);
          item.preco = parseFloat(item.preco);
          item.calculo = calculo;
          item.taxa = taxa;
        } else if (
          item.type === "Peça Principal" ||
          item.type === "Peça Secundária"
        ) {
          const comprimento = parseFloat(item.comprimento);
          const largura = parseFloat(item.largura);
          //calculo é isso
          let calculo;
          if (largura <= 0.1) {
            let dezporcento = (preco / 100) * 10;
            calculo = comprimento * dezporcento;
          } else {
            calculo = comprimento * largura * preco;
          }
          // até aqui
          const taxa = (calculo / 100) * lucro_percentual;
          item.total = parseFloat(quantidade * (taxa + calculo)).toFixed(2);
          item.preco = preco;
          item.calculo = calculo;
          item.taxa = taxa;
        }
        ficha_tecnica.push(item);
      });
      this.product = {
        ...this.product,
        ...p,
        ficha_tecnica
      };
      this.changePlate = false;
      this.processFinalPrice();
    },
    close() {
      this.opened = false;
      this.product = {
        quantity: 1,
        imagens: [],
        ficha_tecnica: []
      };
    },
    initChangeTable() {
      this.changePlate = true;
      setTimeout(() => {
        this.$refs.plateInput.focus();
      }, 300);
    },
    addAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada < parseInt(item_adicional.maximo)
      ) {
        item_adicional.quantidade_adicionada++;
        item_adicional.itens[index_item_adicional].quantidade_adicionada
          ? item_adicional.itens[index_item_adicional].quantidade_adicionada++
          : (item_adicional.itens[
              index_item_adicional
            ].quantidade_adicionada = 1);
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === parseInt(item_adicional.maximo)
      ) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: "Opa, quantidade máxima atingida!",
          timeout: 6000
        });
      } else if (!item_adicional.quantidade_adicionada) {
        item_adicional.quantidade_adicionada = 1;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 1;
      }

      this.product.adicionais[index_adicional] = item_adicional;
      this.$forceUpdate();
      this.processFinalPrice();
    },
    removeAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada > 1
      ) {
        item_adicional.quantidade_adicionada--;
        item_adicional.itens[index_item_adicional].quantidade_adicionada--;
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === 1
      ) {
        item_adicional.quantidade_adicionada = 0;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 0;
      }
      this.product.adicionais[index_adicional] = item_adicional;
      this.processFinalPrice();
    },
    // thiss process product.seller_price
    processFinalPrice() {
      // const aditionals = this.product.adicionais || [];
      const ficha_tecnica = this.product.ficha_tecnica || [];
      let initial_price = 0;
      let aditional_total_price = 0;
      let ficha_total_price = 0;
      // let acabamento_total_price = 0;
      // aditionals.map(adicional => {
      //   adicional.itens.map(item => {
      //     if (item.quantidade_adicionada) {
      //       aditional_total_price +=
      //         parseInt(item.quantidade_adicionada) * parseFloat(item.preco);
      //     }
      //   });
      // });
      ficha_tecnica.map(ficha => {
        if (ficha.quantity) {
          ficha_total_price += parseFloat(ficha.total);
        }
      });
      this.product.seller_price = (initial_price + aditional_total_price + ficha_total_price);
      // this.product.seller_price =
      //   this.product.quantity *
      //   (initial_price + aditional_total_price + ficha_total_price);
      // this.product.aditional_total_price = aditional_total_price;
      this.product.ficha_total_price = ficha_total_price;
      this.$forceUpdate();
    },
    addProduct() {
      const product = this.product;
      // let ok = true;
      // let ok_group = "";
      // if (product.adicionais && product.adicionais.length) {
      //   product.adicionais.map(aditional => {
      //     if (aditional.obrigatorio) {
      //       if (!aditional.quantidade_adicionada) {
      //         ok = false;
      //         ok_group = aditional.nome_grupo;
      //       } else if (
      //         aditional.quantidade_adicionada &&
      //         aditional.quantidade_adicionada < 1
      //       ) {
      //         ok = false;
      //         ok_group = aditional.nome_grupo;
      //       }
      //     }
      //   });
      // }
      // if (!ok) {
      //   this.createGlobalMessage({
      //     type: "info",
      //     icon: "mdi-info",
      //     message: `Opa, o adicional de ${ok_group} é obrigatório!`,
      //     timeout: 6000
      //   });
      // } else {
      this.addProductToCart(product);
      // }
    },
    addProductQtd() {
      this.product.quantity++;
      this.processFinalPrice();
    },
    removeProductQtd() {
      if (this.product.quantity > 1) {
        this.product.quantity--;
      }
      this.processFinalPrice();
    }
  },
  computed: {
    adicionais() {
      const payload = this.products.filter(
        i =>
          i.type === "Acabamento" ||
          i.type === "Peça Principal" ||
          i.type === "Peça Secundária"
      );

      return payload;
    }
  }
};
</script>

<style scoped>
.item-list {
  background: #f0f0f0;
  border-radius: 6px;
}
.adicionals::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.adicionals::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.adicionals::-webkit-scrollbar-thumb {
  background-color: #3eb4df; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.font-orange {
  color: #e1673c;
}
</style>
