<template>
  <v-dialog fullscreen transition="slide-x-transition" v-model="modal">
    <div class="expande-horizontal expande-vertical-vh vitrify centraliza">
      <v-flex xs12 md10>
        <div class="animate__animated expande-horizontal column">
          <div class="expande-horizontal centraliza wrap py-6 pb-0">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-card
                  color=""
                  class="expande-horizontal"
                  style="min-height: 77vh;"
                >
                  <div class="expande-horizontal">
                    <v-flex
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="pa-6"
                      xs12
                      md6
                    >
                      <div
                        style="min-height: 60vh;"
                        class="expande-horizontal pa-6 centraliza"
                      >
                        <LottieAnimation
                          v-if="loading === 'loading_rocket'"
                          class="pa-6"
                          ref="anim"
                          :loop="true"
                          :animationData="
                            require('@/apps/shared/assets/loading_rocket.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'success'"
                          class="pa-6"
                          ref="anim2"
                          :animationData="
                            require('@/apps/shared/assets/activate_account_success.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'man_typing'"
                          :loop="true"
                          class="pa-6 animate__fadeInUp"
                          ref="anim3"
                          :animationData="
                            require('@/apps/shared/assets/manager.json')
                          "
                        />
                      </div>
                    </v-flex>
                    <v-flex class="pa-6" xs12 md6>
                      <v-window v-model="step">
                        <v-window-item :value="1">
                          <div
                            style="min-height: 57vh;"
                            transition="fade"
                            class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                          >
                            <v-flex class="pa-5 pb-2" xs12>
                              <div
                                class="expande-horizontal column animate__fadeIn"
                              >
                                <h1 class="fonte" style="color: #010f50;">
                                  Gerencie
                                </h1>
                                <span class="fonte grey--text"
                                  >Envie para um quadro no gestor de compras:</span
                                >
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <v-flex xs12 class="">
                                <v-list
                                  v-if="loading === 'man_typing'"
                                  nav
                                  two-line
                                  class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                                >
                                  <v-list-item
                                    :class="
                                      indexByArrow === 0
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    :key="status.status"
                                    v-for="status in status_type[
                                      get_compra.tipo
                                    ]"
                                    @click="sendToThisStatus(status)"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      :color="status.color"
                                      >{{ status.icon }}</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        {{ status.text }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-btn
                                  @click="close"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-6"
                                  >Cancelar
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </v-flex>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            transition="fade"
                            v-if="loading === 'success'"
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #010f50;">
                                  Vendido!
                                </h2>
                                <span class="grey--text fonte">
                                  O que fazer agora?
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal wrap"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <v-list-item @click="finishSale('print')">
                                  <v-avatar>
                                    <v-icon>mdi-cast</v-icon>
                                  </v-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Imprimir
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="finishSale('go_gestor')">
                                  <v-avatar>
                                    <v-icon>mdi-blinds</v-icon>
                                  </v-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Ir Para o gestor
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="finishSale('exit')">
                                  <v-avatar>
                                    <v-icon>mdi-arrow-bottom-left</v-icon>
                                  </v-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Sair
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                  </div>
                </v-card>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { EventBus } from "@/main.js";
export default {
  props: {
    initSale: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      modal: false,
      status_type: {
        delivery: [
          {
            icon: "mdi-content-save-outline",
            text: "Orçamento aberto",
            value: "salvo",
            color: "#FF9800"
          },
          {
            icon: "mdi-check-circle-outline",
            text: "Orçamento Fechado",
            value: "orcamento fechado",
            color: "#4CAF50"
          },
          {
            icon: "mdi-clock-outline",
            text: "Em Corte",
            value: "em preparo",
            color: "#8BC34A"
          },
          // {
          //   icon: "mdi-clock-outline",
          //   text: "Acabamento",
          //   value: "acabamento",
          //   color: "#8BC34A"
          // },
          // {
          //   icon: "mdi-rocket-outline",
          //   text: "Pronto",
          //   value: "pronto",
          //   color: "#2196F3"
          // },
          // {
          //   icon: "mdi-rocket-launch-outline",
          //   text: "Saiu p entrega",
          //   value: "despachado",
          //   color: "#2196F3"
          // },
          // {
          //   icon: "mdi-check-circle-outline",
          //   text: "Finalizado",
          //   value: "concluído",
          //   color: "#4CAF50"
          // }
        ]
        // mesa: [
        //   {
        //     icon: "mdi-content-save-outline",
        //     text: "Salvo",
        //     value: "salvo",
        //     color: "#AEEA00"
        //   },
        //   {
        //     icon: "mdi-alarm-light-outline",
        //     text: "Aguardando",
        //     value: "aguardando",
        //     color: "#FF9800"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Em preparo",
        //     value: "em preparo",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Pronto",
        //     value: "pronto",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Em Consumo",
        //     value: "em consumo",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-rocket-outline",
        //     text: "Finalizado",
        //     value: "concluído",
        //     color: "#4CAF50"
        //   }
        // ],
        // balcao: [
        //   {
        //     icon: "mdi-content-save-outline",
        //     text: "Salvo",
        //     value: "salvo",
        //     color: "#AEEA00"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Em preparo",
        //     value: "em preparo",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-basket-outline",
        //     text: "Pronto",
        //     value: "pronto",
        //     color: "#2196F3"
        //   },
        //   {
        //     icon: "mdi-check-circle-outline",
        //     text: "Finalizado",
        //     value: "concluído",
        //     color: "#4CAF50"
        //   }
        // ]
      },
      valueToPayInSelectedMethod: "",
      loading: "",
      step: 1,
      indexByArrow: 0,
      remainingToPay: false,
      type: "", //payment_method_selected,
      successData: {},
      blockClickToSendGestor: false
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_compra"])
  },
  methods: {
    finishSale(type_action) {
      this.$store.commit("set_compra", {
        cliente: {},
        produtos: [],
        tipo: "delivery",
        subtotal: 0
      });
      this.blockClickToSendGestor = false;
      switch (type_action) {
        case "print":
          this.printCompra();
          break;
        case "go_gestor":
          this.$router.push(`/gestordepedidos`);
          break;
        case "exit":
          this.close();
          break;
        default:
          break;
      }
    },
    ...mapActions(["iniciar_pedido"]),
    async sendToThisStatus(status) {
      if (!this.blockClickToSendGestor) {
        this.blockClickToSendGestor = true;
        this.get_compra.initialStatus = status.value || "";
        // this.setLoading("loading_rocket");
        const res = await this.iniciar_pedido();
        if (res.status === 200) {
          this.setLoading("success");
          this.successData = res.data;
          this.step = 2;
        } else {
          this.setLoading("man_typing");
        }
      }
    },
    printCompra() {
      EventBus.$emit("print_compra", this.successData);
    },
    setLoading(state) {
      this.loading = "";
      setTimeout(() => {
        this.loading = state;
        this.$forceUpdate();
      }, 300);
      this.$forceUpdate();
    },
    open() {
      this.modal = false;
      this.modal = true;
      this.setLoading("");
      if (this.get_compra._id) {
        this.sendToThisStatus({});
      } else {
        setTimeout(() => {
          this.setLoading("man_typing");
          this.step = 1;
        }, 100);
      }
    },
    close() {
      this.modal = false;
    },
    stepBack() {
      if (this.step === 1) {
        this.close();
      } else {
        this.step--;
      }
    }
  },
  mounted() {
    EventBus.$on("ArrowUp", () => {
      if (this.indexByArrow > 0) {
        this.indexByArrow--;
      }
    });
    EventBus.$on("ArrowDown", () => {
      if (this.indexByArrow < 3) {
        this.indexByArrow++;
      }
    });
  }
};
</script>

<style>
.paymentMethodPreSelected {
  background: #f0f0f0;
  border-radius: 6px;
}
</style>
