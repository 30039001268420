<template>
  <v-dialog width="300" v-model="opened" transition="slide-x-transition">
    <div style="background: #f2f2f2;" class="expande-horizontal wrap">
      <v-flex xs12>
        <v-list-item class="fonte ma-0 pa-0">
          <v-btn class="mr-2" icon @click="close(true)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-list-item-content>
            <v-list-item-title class="fonte font-weight-bold">
              Desconto
            </v-list-item-title>
            <v-list-item-subtitle class="fonte">
              O valor será descontado da compra
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-flex>
      <div class="expande-horizontal pb-1 pr-1 pl-1">
        <v-btn
          @click="type = 'percent'"
          :text="type !== 'percent'"
          class="mr-2"
        >
          <v-icon :color="$theme.primary">
            mdi-percent
          </v-icon>
        </v-btn>
        <v-btn @click="type = 'real'" :text="type !== 'real'" class="fonte">
          <span class="font-weight-bold" :style="`color: ${$theme.primary}`">
            R$
          </span>
        </v-btn>
      </div>
      <div class="expande-horizontal pt-2 pb-2 pr-1 pl-1">
        <v-text-field
          v-if="type === 'real'"
          outlined
          hide-details
          @keyup.enter="close()"
          v-mask="['#', '##', '#,##', '##,##', '###,##', '####,##', '#####,##']"
          prefix="R$"
          autofocus
          v-model="value"
        ></v-text-field>
        <v-text-field
          v-if="type === 'percent'"
          outlined
          hide-details
          @keyup.enter="close()"
          v-mask="['#', '##']"
          prefix="%"
          autofocus
          v-model="value"
        ></v-text-field>
      </div>
      <div class="expande-horizontal fonte pb-1 pl-1 pr-1">
        <v-btn @click="close()" block :color="$theme.secondary" dark>Aplicar desconto</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      opened: false,
      type: "percent",
      value: ""
    };
  },
  computed: {
    ...mapGetters(["get_compra"])
  },
  methods: {
    ...mapActions(["calcula_valor_total_da_compra"]),
    open() {
      this.opened = true;
    },
    close(exit) {
      if (exit) {
        this.opened = false;
      } else {
        const type = this.type;
        if (type === "percent") {
          this.get_compra.desconto =
            (this.get_compra.subtotal / 100) * parseInt(this.value);
          this.get_compra.desconto_type = this.type;
          this.get_compra.desconto_applyed = parseInt(this.value);
        }
        if (type === "real") {
          this.get_compra.desconto = parseFloat(
            this.value.replace(",", ".")
          ).toFixed(2);
          this.get_compra.desconto_type = this.type;
          this.get_compra.desconto_applyed = parseFloat(
            this.value.replace(",", ".")
          );
        }
        this.calcula_valor_total_da_compra();
        this.$forceUpdate();
        this.opened = false;
      }
    }
  }
};
</script>
