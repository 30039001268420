<template>
  <v-dialog
    transition="slide-x-transition"
    class="roller"
    width="800"
    height="700"
    v-model="opened"
  >
    <v-card width="800" class="roller">
      <v-flex class="roller" xs12>
        <div class="expande-horizontal wrap roller">
          <v-flex xs12>
            <div class="expande-horizontal centraliza pa-3">
              <v-btn class="fonte text-capitalize" text @click="close">
                <v-icon>mdi-chevron-left</v-icon>
                Fechar
              </v-btn>
              <v-spacer></v-spacer>
            </div>
          </v-flex>
          <v-flex
            v-for="(mesa, index) in mesas_disponiveis"
            :key="`id-mesa-${index}`"
            xs4
            v-show="mesa.active"
            md2
            class="pa-6"
          >
            <v-card
              @click="selecionarMesa(mesa)"
              hover
              dark
              class="fonte cardMesa"
            >
              <v-flex xs12>
                <div
                  class="expande-horizontal centraliza column pt-3 pb-3"
                  :class="{ 'pt-6 pb-6': $vuetify.breakpoint.smAndUp }"
                >
                  <h1 class="fonte font-sized">{{ mesa.number }}</h1>
                  <span
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="fonte font--mini"
                  >
                    {{ mesa.description }}
                  </span>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    mesas_disponiveis: {
      type: Array,
      default: [],
      required: true
    },
    selecionarMesa: {
      type: Function,
      required: () => {}
    }
  },
  data() {
    return {
      opened: false
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
};
</script>

<style scoped>
.item-list {
  background: #f0f0f0;
  border-radius: 6px;
}
.roller::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.roller::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.roller::-webkit-scrollbar-thumb {
  background-color: #010f50; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.font-sized {
  font-size: 1rem;
}
.font--mini {
  font-size: 0.7rem;
}
</style>
