<template>
  <div>
    <!-- <v-btn @click="getBluetoothPrinters">Get devices</v-btn>
    <select v-model="selectedPrinter">
      <option
        :key="`printer-${i}`"
        v-for="(printer, i) in printers"
        :value="printer"
        >{{ printer }}</option
      >
    </select>
    <button @click="printText">Print</button> -->
  </div>
</template>

<script>
// import noble from "noble";
// import BluetoothSerialPort from "bluetooth-serial-port";

export default {
  data() {
    return {
      printers: [],
      selectedPrinter: null,
      btSerial: null,
      // noble: noble
    };
  },
  created() {
    this.getBluetoothPrinters();
  },
  methods: {
    getBluetoothPrinters() {
      // this.noble.on("stateChange", state => {
      //   console.log("re");
      //   if (state === "poweredOn") {
      //     // noble.startScanning();
      //   } else {
      //     // noble.stopScanning();
      //   }
      // });

      // this.noble.on("discover", peripheral => {
      //   console.log("ok", peripheral);
      //   let device = {
      //     name: peripheral.advertisement.localName,
      //     id: peripheral.id
      //   };
      //   this.printers.push(device);
      // });
    },
    printText() {
      // if (this.selectedPrinter) {
      //   this.btSerial = new BluetoothSerialPort.BluetoothSerialPort();
      //   this.btSerial.findSerialPortChannel(this.selectedPrinter.id, (channel) => {
      //     this.btSerial.connect(this.selectedPrinter.id, channel, () => {
      //       let data = "Hello World!";
      //       this.btSerial.write(data, (err) => {
      //         if (err) return console.log(err);
      //         console.log("Data sent to printer successfully");
      //       });
      //     }, (err) => {
      //       console.log(err);
      //     });
      //   }, (err) => {
      //     console.log(err);
      //   });
      // } else {
      //   console.log("No printer selected.");
      // }
    }
  }
};
</script>
